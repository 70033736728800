<script>
import PlayComponent from '../components/PlayComponent.vue';
import positionMixin from '@/mixins/positionMixin.js'
import playDataMixin from '@/mixins/getPlayDataMixin.js'
export default {
    name: 'GenericPlay', //not sure how to get around this
    components: {PlayComponent},
    data() {
        return {
            positions: null,
            puck: null,
            name: '',
            playId:null,
            type: null
        }
    },
    mixins: [positionMixin, playDataMixin],
    methods: {
        updatePositions() {
            this.positions = this.calculateOtherSide(this.positions)
            this.puck = this.calculatePuckPathRight(this.puck)
            this.puck = this.calculateMovingPuckPathRight(this.puck) //this is ugly
        }
    },
    computed: {
        positionProps() {
            const props = {};
            const possiblePositions = ['LWO', 'RWO', 'CO', 'LDO', 'RDO', 'LWD', 'RWD', 'LDD', 'RDD', 'CD'];
            possiblePositions.forEach(pos => {
                if (this.positions && this.positions[pos]) {
                    props[pos] = this.positions[pos];
                }
            });
            return props;
        },
    },
    async created() {
        this.playId = this.$route.query.id
        this.type = this.$route.query.type
        this.name = this.$route.params.name
        try {
            const playData = await this.fetchPlayData(this.type,this.playId) //maybe hide this number in a .env file later on
            this.positions = playData[0].positions
            this.puck = playData[0].puck
            this.updatePositions()
        } catch(err) {
            //idk what to do with this error
            console.log("An error has occured ", err)
        }
    }
}

</script>

<template>
    <PlayComponent v-if="positions && puck" :puck="puck" v-bind="positionProps" :type="this.type"></PlayComponent>
</template>

