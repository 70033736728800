<script>
export default {
    name: 'ViewPathButton',
    props: {
        isAnimated: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            eyeOpen: true,
            state: "Hide Paths"
        }
    },
    methods : {
        flipEye() {
            this.eyeOpen = !this.eyeOpen

            if(this.state === "Hide Paths") {
                this.state = "Show Paths"
            } else {
                this.state = "Hide Paths"
            }
        }
    }
}
</script>

<template>
    <button @click="flipEye" type="button" class="text-gray-900 bg-white border border-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600"
    :class="{
            'hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 focus:outline-none dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 cursor-pointer': isAnimated,
            'opacity-50 cursor-not-allowed': !isAnimated
        }"
        :disabled="!isAnimated"
    >{{ this.state }}</button>
</template>

<style scoped>

</style>