<script>
/* eslint-disable */
export default {
    name: "Button",
    props : {
        text : String,
        route: String
    },
    data() {
        return {
            img_path : 'button-background.png'
        }
    },
    methods: {
        goToPath() {
            this.$router.push(this.route)
        }
    }
}
</script>

<template>
    <div class="real-button" @click="goToPath" :style="{ backgroundImage: 'url(' + require('@/assets/' + img_path) + ')' }">
      <p class="button-text">{{ text }}</p>
    </div>
</template>

<style scoped>
.real-button{
    border-color: black;
    cursor:pointer;
    border: 1px solid white;
    opacity: 60%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-text {
    color: white;
    font-weight: bolder;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}


</style>
