<script>
export default {
    name: 'PlayCard',
    props: {
        title: {
            type: String,
            required: true
        },
        desc: {
            type: String,
            required: true,
        },
        path: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            required: true
        },
        isAnimated: {
            type: String,
            required: true
        }
    },
    methods: {
        goToPath() {
            const base = this.type //offense, defensive-alignment, ect
            this.$router.push({
                path: `/${base}/${this.path}`,
                query: {id : this.id, type: this.type, isAnimated: this.isAnimated}
            })
        }
    }
}
</script>

<template>
  <a @click="goToPath" class="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 items-center cursor-pointer">
    <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{{ title }}</h5>
    <p class="font-normal text-gray-700 dark:text-gray-400">{{ desc }}</p>
  </a>
</template>