const positionMixin = {
    methods: {
        calculateOtherSide(flipPositions) { 
            let positions = this.calculateFlipPositions(flipPositions)
            return positions
        },
        calculateNewNames(name) {
            if(name.charAt(0) === 'L' || name.charAt(0) === 'R') {
                let newName
                if(name.charAt(0) === 'L') {
                    newName = 'R' + name.slice(1)
                }
                else {
                    newName = 'L' + name.slice(1)
                }
                return newName
            }
            return name

        },
        calculateFlipPositions(positions) {
            const imageWidth = 700;
        
            // Function to handle swapping and flipping of positions
            const swapAndFlip = (pos1, pos2) => {
                if (positions[pos1] && positions[pos2]) {
                    // Swap names and generate new right-side names
                    positions[pos1].rightSideName = this.calculateNewNames(positions[pos2].name);
                    positions[pos2].rightSideName = this.calculateNewNames(positions[pos1].name);
        
                    // Swap Y values
                    positions[pos1].rightSideY = positions[pos2].y;
                    positions[pos2].rightSideY = positions[pos1].y;
        
                    // Recalculate X values
                    positions[pos1].rightSideX = imageWidth - positions[pos2].x;
                    positions[pos2].rightSideX = imageWidth - positions[pos1].x;
        
                    // Swap animation paths if animated
                    if (positions[pos1].animated) {
                        positions[pos2].rightSideAnimated = true;
                        positions[pos2].rightSidePath = this.finalGenerateRightSidePath(positions[pos1].leftPath);
                    }
        
                    if (positions[pos2].animated) {
                        positions[pos1].rightSideAnimated = true;
                        positions[pos1].rightSidePath = this.finalGenerateRightSidePath(positions[pos2].leftPath);
                    }
        
                    // Swap durations
                    let tempDuration = positions[pos1].duration;
                    positions[pos1].rightSideDuration = positions[pos2].duration;
                    positions[pos2].rightSideDuration = tempDuration;
        
                    // Swap delays
                    let tempDelay = positions[pos1].delay;
                    positions[pos1].rightSideDelay = positions[pos2].delay;
                    positions[pos2].rightSideDelay = tempDelay;
                }
            };
        
            // Wings
            swapAndFlip('LWO', 'RWO');
        
            // Defensemen
            swapAndFlip('LDO', 'RDO');
        
            // Center - no swap, only flip for the same position
            if (positions.CO) {
                positions.CO.rightSideName = positions.CO.name;
                positions.CO.rightSideY = positions.CO.y;
                positions.CO.rightSideX = imageWidth - positions.CO.x;
                if (positions.CO.animated) {
                    positions.CO.rightSideAnimated = true;
                    positions.CO.rightSidePath = this.finalGenerateRightSidePath(positions.CO.leftPath);
                }
                positions.CO.rightSideDuration = positions.CO.duration;
                positions.CO.rightSideDelay = positions.CO.delay;
            }
        
            // Additional Defensive Players
            swapAndFlip('CD', 'CD'); // Center Defense - same logic as center, flipping only
            swapAndFlip('LWD', 'RWD'); // Left Wing Defense and Right Wing Defense
            swapAndFlip('LDD', 'RDD'); // Left Deep Defense and Right Deep Defense
        
            return positions;
        },
        calculatePuckPathRight(puck) {
            let arr = []
            for(let i = 0; i < puck.highlightedPaths.length; i++) {
                const rightPath = this.finalGenerateRightSidePath(puck.highlightedPaths[i])
                arr.push(rightPath)
            }
            puck.highlightedPathsRight = arr
            return puck
        },
        calculateMovingPuckPathRight(puck) {
            let arr = []
            for(let i = 0; i < puck.animationPaths.length; i++) {
                let rightSidePath = this.finalGenerateRightSidePath(puck.animationPaths[i])
                //console.log("Path generated for the puck was: " + rightSidePath)
                arr.push(rightSidePath)
            }
            puck.animationPathsRight = arr
            return puck
        },
        // generateRightSidePath(pathStr) {
        //     let imageWidth = 700;
        //     const pathCommands = pathStr.match(/[a-zA-Z][^a-zA-Z]*/g);
        
        //     const mirroredPathCommands = pathCommands.map((command, index) => {
        //         const type = command[0];
        //         const coords = command.slice(1).trim().split(/[ ,]+/).map(parseFloat);
        
        //         switch (type) {
        //             case 'm':
        //                 if (index === 0) {
        //                     return `m${imageWidth - coords[0]},${coords[1]}`;
        //                 } else {
        //                     return `m${-coords[0]},${coords[1]}`;
        //                 }
        //             case 'c':
        //                 return `c${-coords[0]},${coords[1]} ${-coords[2]},${coords[3]} ${-coords[4]},${coords[5]}`;
        //             case 'M':
        //                 return `M${imageWidth - coords[0]},${coords[1]}`;
        //             case 'C':
        //                 return `C${imageWidth - coords[0]},${coords[1]} ${imageWidth - coords[2]},${coords[3]} ${imageWidth - coords[4]},${coords[5]}`;
        //             case 'l':
        //                 return `l${-coords[0]},${coords[1]}`;
        //             case 'L':
        //                 return `L${imageWidth - coords[0]},${coords[1]}`;
        //             case 'h':
        //                 return `h${-coords[0]}`;
        //             case 'H':
        //                 return `H${imageWidth - coords[0]}`;
        //             case 'v':
        //             case 'V':
        //                 // Vertical coordinates don't change when mirroring horizontally
        //                 return `${type}${coords[0]}`;
        //             case 's':
        //                 return `s${-coords[0]},${coords[1]} ${-coords[2]},${coords[3]}`;
        //             case 'S':
        //                 return `S${imageWidth - coords[0]},${coords[1]} ${imageWidth - coords[2]},${coords[3]}`;
        //             case 'q':
        //                 return `q${-coords[0]},${coords[1]} ${-coords[2]},${coords[3]}`;
        //             case 'Q':
        //                 return `Q${imageWidth - coords[0]},${coords[1]} ${imageWidth - coords[2]},${coords[3]}`;
        //             case 't':
        //                 return `t${-coords[0]},${coords[1]}`;
        //             case 'T':
        //                 return `T${imageWidth - coords[0]},${coords[1]}`;
        //             case 'a':
        //                 // Mirroring an arc requires flipping the sweep flag
        //                 return `a${coords[0]},${coords[1]} ${coords[2]} ${coords[3]} ${coords[4] === 1 ? 0 : 1} ${-coords[5]},${coords[6]}`;
        //             case 'A':
        //                 // Mirroring an arc requires flipping the sweep flag
        //                 return `A${coords[0]},${coords[1]} ${coords[2]} ${coords[3]} ${coords[4] === 1 ? 0 : 1} ${imageWidth - coords[5]},${coords[6]}`;
        //             case 'z':
        //             case 'Z':
        //                 // Close path commands are unchanged
        //                 return command;
        //             default:
        //                 return command;
        //         }
        //     });
        
        //     return mirroredPathCommands.join(' ');
        // },
        // generateRightSidePuckPath(pathStr) {
        //     let imageWidth = 700;
        //     const pathCommands = pathStr.match(/[a-zA-Z][^a-zA-Z]*/g);

        //     const mirroredPathCommands = pathCommands.map((command) => {
        //         const type = command[0];
        //         const coords = command.slice(1).trim().split(/[ ,]+/).map(parseFloat);

        //         switch (type) {
        //             case 'm':
        //             case 'M': {
        //                 // Handle all pairs of coordinates
        //                 const mirroredCoords = [];
        //                 for (let i = 0; i < coords.length; i += 2) {
        //                     mirroredCoords.push(imageWidth - coords[i], coords[i + 1]);
        //                 }
        //                 return `${type}${mirroredCoords.join(',')}`;
        //             }
        //             case 'c':
        //             case 'C': {
        //                 // Handle all pairs of coordinates
        //                 const mirroredCoords = [];
        //                 for (let i = 0; i < coords.length; i += 2) {
        //                     mirroredCoords.push(imageWidth - coords[i], coords[i + 1]);
        //                 }
        //                 return `${type}${mirroredCoords.join(',')}`;
        //             }
        //             default:
        //                 return command;
        //         }
        //     });

        //     return mirroredPathCommands.join(' ');
        // },
        finalGenerateRightSidePath(path) {
           // Split the path into components (commands and coordinates)
            const tokens = path.split(/(?=[A-Za-z])/);
            let flippedPath = '';

            // Process each segment of the path
            tokens.forEach(token => {
                const command = token[0];
                const coordinates = token.slice(1).trim();

                // Check if there are coordinates to process
                if (coordinates.length > 0) {
                    const points = coordinates.split(/[\s,]+/).map(parseFloat);
                    // Process each coordinate pair
                    for (let i = 0; i < points.length; i += 2) {
                        points[i] = 700 - points[i]; // Flip the x coordinate
                    }
                    flippedPath += command + ' ' + points.join(' ');
                } else {
                    // For commands that do not have coordinates, just add the command
                    flippedPath += command;
                }
            });

            return flippedPath; 
        }
    }
}

export default positionMixin