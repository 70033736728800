<script>
/* eslint-disable */
import axiosInstance from '@/utils/axiosConfig'
import Button from '@/components/Button.vue'
import NavBar from '@/components/NavBar.vue'
import PlayCard from '@/components/PlayCard.vue'
import NewNavBar from '@/components/NewNavBar.vue'
import { format } from '@/utils/formatType.js'
export default {
    name: 'HomeView',
    components: {Button, NavBar, PlayCard, NewNavBar},
    data() {
      return {
        cards: [],
        error: null
      }
    },
    props: {
      //type refers to things such as /offense, /defensive-alignment, ect
      type: {
        type: String,
        required : true
      }
    },
    methods: {
      async fetchCards() {
        try {
            const response = await axiosInstance.get(`${process.env.VUE_APP_API_ROUTE}/${this.type}`)
            const playData = response.data
            this.cards = playData
        } catch(err) {
          //remove before production
          if(err.response && err.response.status === 401) {
            //redirect to login
            this.$router.push('/login')
          } else {
            throw err
          }
        }
      }
    },
    async mounted() {
      try {
        await this.fetchCards()
      } catch(err) {
        this.error = 'Failed to load plays. Please try again later'
      }
    },
    watch: {
      '$route'() {
        this.fetchCards()
      }
    },
    computed: {
      formattedType() {
        return format(this.type)
      }
    }
}
</script>

<template>
  <div class="max-w-6xl mx-auto px-4">
    <NewNavBar :text="formattedType"/>
    <div v-if="error" class="text-red-500 text-center my-4">{{ error }}</div>
    <div class="mt-4 flex justify-center">
      <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3 gap-4">
        <PlayCard
          v-for="card in cards"
          :key="card.id"
          :id="card.id"
          :title="card.title"
          :desc="card.desc"
          :path="card.path"
          :type="this.type"
          :isAnimated="card.isAnimated"
          class="w-full"
        />
      </div>
    </div>
  </div>
</template>
