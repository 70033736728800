<script>
import axiosInstance from '@/utils/axiosConfig'

export default {
  name: 'PassCodeInput',
  data() {
    return {
      inputs: [],
      msg : 'Please enter the 6-digit access code.',
      isError: false
    }
  },
  methods: {
    focusNext(index) {
      if (index < 5) {
        this.inputs[index + 1].focus()
      }
    },
    focusPrev(index) {
      if (index > 0) {
        this.inputs[index - 1].focus()
      }
    },
    handleInput(event, index) {
      const input = event.target
      if (input.value.length === 1) {
        this.focusNext(index)
      }
    },
    handleKeyDown(event, index) {
      if (event.key === 'Backspace' && event.target.value === '') {
        this.focusPrev(index)
      }
    },
    setInputRef(el, index) {
      if (el) {
        this.inputs[index] = el
      }
    },
    resetError() {
      this.isError = false
      this.msg = 'Please enter the 6-digit access code.'
    },
    async handleSubmit() {
      //we will handle submit logic here.
      try {
        const code = this.inputs.map(input => input.value).join('');
        await axiosInstance.post(`${process.env.VUE_APP_API_ROUTE}/login`, {pin : code}) 
        this.$router.push('/offense')
      } catch (error) {
        //Show message to user later eventually, just log for now
        this.isError = true
        if(error.response) {
          switch(error.response.status) {
            case 401:
              this.msg = 'Password was incorrect. Please try again.'
              break
            case 429:
              this.msg = 'Too many attempts have been made. Please try again later.'
              break
            default:
              this.msg = 'An error has occured. Please try again later.'
          }
        } else if(error.request) {
          //request was made but nothing came back from server
          this.msg = 'No response from server. Please try again later.'
        } else {
          this.msg = 'An error has occured. Please try again later.'
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.inputs.length > 0) {
        this.inputs[0].focus()
      }
    })
  }
}
</script>

<template>
  <div class="flex justify-center items-center min-h-screen bg-gray-100 dark:bg-gray-900 px-4">
    <div class="w-full max-w-sm sm:max-w-md p-4 sm:p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">
      <form @submit.prevent="handleSubmit">
        <div class="flex justify-center items-center mb-4">
          <div class="flex space-x-1 xs:space-x-2 rtl:space-x-reverse">
            <div v-for="n in 6" :key="n">
              <label :for="`code-${n}`" class="sr-only">Code {{ n }}</label>
              <input
                type="text"
                inputmode="numeric"
                maxlength="1"
                :id="`code-${n}`"
                :ref="el => setInputRef(el, n-1)"
                @input="handleInput($event, n-1); resetError"
                @keydown="handleKeyDown($event, n-1)"
                class="block w-8 xs:w-9 sm:w-10 h-10 xs:h-11 sm:h-12 text-base xs:text-lg sm:text-xl font-extrabold text-center text-gray-900 bg-white border border-gray-300 rounded-lg focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 p-0"
                required
              />
            </div>
          </div>
          <button type="submit" class="ml-2 sm:ml-4 p-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
            </svg>
          </button>
        </div>
        <p id="helper-text-explanation"  :class="[
     'mt-4 text-xs xs:text-sm text-center',
     isError ? 'text-red-500 dark:text-red-400' : 'text-gray-500 dark:text-gray-400'
      ]">
          {{ this.msg }}
        </p>
      </form>
    </div>
  </div>
</template>