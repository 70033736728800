import axiosInstance from '@/utils/axiosConfig'

const playDataMixin = {
    methods: {
        async fetchPlayData(type,id) {
            try {
                const response = await axiosInstance.get(`${process.env.VUE_APP_API_ROUTE}/${type}/${id}`)
                const playData = response.data
                return playData
            } catch(err) {
                if(err.response && err.response.status === 401) {
                    //redirect to login, not working also redundant
                    this.$router.push('/login')
                } else {
                   throw err
                }
            }
        }
    }
}

export default playDataMixin