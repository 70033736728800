<script>
import PassCodeInput from '@/components/PassCodeInput.vue'

export default {
    name: 'LoginPage',
    components: {PassCodeInput}
}
</script>

<template>
<!--Flowbite code-->
<div>
    <PassCodeInput/>
</div>
</template>