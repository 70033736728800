<script>
import { gsap } from 'gsap'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'
gsap.registerPlugin(MotionPathPlugin);

export default {
    name: 'HockeyRink',
    data() {
        return {
            leftSide: true,
            showPath: true,
            showPuckPath: true
        }
    },
    props: {
        LWO: {
            type: Object,
            required: false
        },
        LWD: {
            type: Object,
            required: false
        },
        CO: {
            type: Object,
            required: false
        },
        CD: {
            type: Object,
            required: false
        },
        RWO: {
            type: Object,
            required: false
        },
        RWD: {
            type: Object,
            required: false
        },
        LDO: {
            type: Object,
            required: false
        },
        LDD: {
            type: Object,
            required: false
        },
        RDO: {
            type: Object,
            required: false
        },
        RDD: {
            type: Object,
            required: false
        },
        puck: {
            type: Object,
            required:false
        }
    },
    computed: {
        logoURL() {
            return require('@/assets/qu-logo.png')
       },
    },
    mounted() {
        this.generateDOMPaths() //computed does not work for this, since this.$refs is not populated until mounted
        this.highlightPaths()
        this.generatePuckDOMPaths()
    },
    methods: {
        runPlay() {
            this.animatePuck(0)
            this.showPuckPath = false;

            this.$nextTick(() => {
                // console.log("Initial check of all refs:");
                // for (let player in this.$refs) {
                //     //console.log(this[player]?.name + " has been found on the outside of the mainloop")
                // }
                //console.log("Entering main animation loop:")
                for (let player in this.$refs) {
                    if (this.$refs[player] !== this.$refs.puck && (this[player]?.animated || this[player]?.rightSideAnimated)) {
                        let pathElement
                        let duration
                        let delay

                        //console.log(`Preparing to animate ${this[player]?.name}`)

                        if (this.leftSide && this[player].animated) {
                            pathElement = document.querySelector(`#customPath${this[player].name}`);
                            delay = this[player].delay;
                            duration = this[player].duration;
                            //console.log(`Animating on left side: ${this[player]?.name} with path ${pathElement}`);
                        } else if (!this.leftSide && this[player].rightSideAnimated) {
                            pathElement = document.querySelector(`#customPathRight${this[player].name}`);
                            duration = this[player].rightSideDuration;
                            delay = this[player].rightSideDelay;
                            //console.log(`Animating on right side: ${this[player]?.name} with path ${pathElement}`);
                        } else {
                            //console.log(`Skipping player: ${this[player]?.name}`);
                            continue; 
                        }

                        gsap.set(this.$refs[player], { transformOrigin: "50% 50%" });
                        gsap.to(this.$refs[player], {
                            duration: duration,
                            delay: delay || 0,
                            motionPath: {
                                path: pathElement,
                                align: pathElement,
                                alignOrigin: [0.5, 0.5]
                            },
                            ease: 'power1.inOut'
                        });
                    } else {
                        //console.log(`Not animating ${this[player]?.name}: No animation conditions met`);
                    }
                }
            });
        },
        animatePuck(index) {
            //console.log("The path element for the second path of princeton is " + document.querySelector("#puckPathRight1").getAttribute('d'))
            if(index < this.puck.animationPaths.length) {
                const puck = this.$refs.puck //cant use this.puck since it has to be css
                let pathElement = this.leftSide ? document.querySelector(`#puckPathLeft${index}`) : document.querySelector(`#puckPathRightAnimated${index}`)
                //console.log(pathElement.getAttribute('d') + " is the right path being animated now with a delay of " + this.puck.durations[index] + " with an id for " + pathElement.getAttribute('id'))
                let duration = this.puck.durations[index]
                gsap.set(puck, {transformOrigin: "50% 50%"})
                gsap.to(puck, {
                    duration: duration,
                    delay: 0,
                    motionPath: {
                        path: pathElement,
                        align: pathElement,
                        alignOrigin: [0.5, 0.5]
                    },
                    ease: 'power1.inOut',
                    onComplete: () => {
                        this.animatePuck(index + 1) //pretty cool
                    }
                })
            }

        },
        shootPuck() {
            //if we call shootPuck, we should move the puck from where it is to the net.
        },
        generatePuckDOMPaths() {
            let parentSVG = document.querySelector('#parentSvg')

            for(let i = 0; i < this.puck.animationPaths.length; i++) {
                let puckPathLeft = this.puck.animationPaths[i]
                let puckPathRight = this.puck.animationPathsRight[i]

                let pathElementLeft = document.createElementNS('http://www.w3.org/2000/svg', 'path')
                pathElementLeft.setAttribute('d', puckPathLeft)
                pathElementLeft.setAttribute('stroke', 'none')
                pathElementLeft.setAttribute('stroke-width', '1')
                pathElementLeft.setAttribute('fill', 'none')
                pathElementLeft.setAttribute('id', `puckPathLeft${i}`)
                parentSVG.appendChild(pathElementLeft)

                //now right side
                let pathElementRight = document.createElementNS('http://www.w3.org/2000/svg', 'path')
                pathElementRight.setAttribute('d', puckPathRight)
                pathElementRight.setAttribute('stroke', 'none')
                pathElementRight.setAttribute('stroke-width', '1')
                pathElementRight.setAttribute('fill', 'none')
                pathElementRight.setAttribute('id', `puckPathRightAnimated${i}`) 
                parentSVG.appendChild(pathElementRight)
            }
        },
        highlightPaths() {
            //awful code
            //legit disastrous
            if(this.showPath) {
                let string
                if(this.leftSide) {
                    string = 'puckPath'
                        for(let i = 0; i < this.puck.highlightedPaths.length; i++) {
                        let pathElement = document.querySelector(`#${string}${i}`)
                        const length = pathElement.getTotalLength() 
                        gsap.fromTo(pathElement, 
                        { strokeDashoffset: length },
                        { strokeDashoffset: 0, duration: 10, repeat: -1, ease:"none" }
                        );
                    }
                }
                
                for(let i = 0; i < this.puck.highlightedPathsRight.length; i++) {
                    let pathElement = document.querySelector(`#puckPathRight${i}`)
                    const length = pathElement.getTotalLength()
                    gsap.fromTo(pathElement, 
                    { strokeDashoffset: length },
                    { strokeDashoffset: 0, duration: 10, repeat: -1, ease:"none" }
                    );
                }
                
                
            }
        },
        reset() {
            this.showPuckPath = true
            for (let player in this.$refs) { 
                if(this.$refs[player] !== this.$refs.puck) {
                    gsap.killTweensOf(this.$refs[player]) //this should immediately stop their animations if they are being animated on
                    if(this.leftSide) {
                        gsap.set(this.$refs[player], {
                        clearProps: "all", //apparently this uses css transform
                        x: this[player].x,
                        y: this[player].y
                        })
                    } else {
                        gsap.set(this.$refs[player], {
                        clearProps: "all", //apparently this uses css transform
                        x: this[player].rightSideX,
                        y: this[player].rightSideY
                    })
                    }
                } else {
                    gsap.killTweensOf(this.$refs.puck)
                    this.resetPuck()
                }
            }
        },
        resetPuck() {
            const puck = this.$refs.puck
            if(this.leftSide) {
                gsap.set(puck, {
                    clearProps: "all",
                    attr: { cx: 273.22, cy: 368.77 }
                }) 
            } else {
                gsap.set(puck, {
                    clearProps: "all",
                    attr: { cx: 426.78, cy: 368.77 }
                }) 
            }
        },
        switchSides() {
            //this is gonna be weird
            this.leftSide = !this.leftSide
            this.reset()
        },
        createPathElement(player) {
            //plz work
            let path = player.leftPath
            let pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path')
            pathElement.setAttribute('id', `customPath${player.name}`)
            pathElement.setAttribute('d', path)
            pathElement.setAttribute('stroke', 'black')
            pathElement.setAttribute('stroke-width', '3')
            pathElement.setAttribute('fill', 'none')
            pathElement.setAttribute('marker-end', 'url(#arrowhead)')
            return pathElement    
        },
        createPathElementRight(player) {
            let path = player.rightSidePath
            //console.log("The path for the " + player.name + " is " + player.rightSidePath)
            let pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path')
            pathElement.setAttribute('id', `customPathRight${player.name}`)
            pathElement.setAttribute('d', path)
            pathElement.setAttribute('stroke', 'black')
            pathElement.setAttribute('stroke-width', '3')
            pathElement.setAttribute('fill', 'none')
            pathElement.setAttribute('marker-end', 'url(#arrowhead)')
            return pathElement  
        },
        createPuckPaths(side) { //this is only for highlighted paths
            let arr = []
            if(side === 'left') {
                for(let i = 0; i < this.puck.highlightedPaths.length; i++) {
                let path = this.puck.highlightedPaths[i]
                let pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path')
                pathElement.setAttribute('id', `puckPath${i}`)
                pathElement.setAttribute('d', path)
                pathElement.setAttribute('stroke-width', '2')
                pathElement.setAttribute('stroke-dasharray', '5,5') 
                pathElement.setAttribute('fill', 'none')
                pathElement.setAttribute('stroke', 'black')
                //pathElement.setAttribute('filter', 'url(#glow)')
                arr.push(pathElement)
                }
            } else {
                for(let i = 0; i < this.puck.highlightedPathsRight.length; i++) {
                    let path = this.puck.highlightedPathsRight[i]
                    let pathElement = document.createElementNS('http://www.w3.org/2000/svg', 'path')
                    pathElement.setAttribute('id', `puckPathRight${i}`)
                    pathElement.setAttribute('d', path)
                    pathElement.setAttribute('stroke-width', '2')
                    pathElement.setAttribute('stroke-dasharray', '5,5') 
                    pathElement.setAttribute('fill', 'none')
                    pathElement.setAttribute('stroke', 'black')
                    //pathElement.setAttribute('filter', 'url(#glow)')
                    arr.push(pathElement) //should work
                }
            }
            return arr
        },
        drawLines() {
            //with a button, users can choose whether or not to see the animation path an element takes
            //would be cool to someone add the line elements all to a group, then conditionally render based off of showPath
            this.showPath = !this.showPath
            this.reset()
        },
        generateDOMPaths() {
            for(let player in this.$refs) {
                if(this.$refs[player] !== this.$refs.puck) {
                    if(this[player].animated) {
                        let parentSVG = document.querySelector('#pathGroup')
                        const newPath = this.createPathElement(this[player]) 
                        parentSVG.appendChild(newPath)
                    }

                    if(this[player].rightSideAnimated) {
                        let rightSideSVG = document.querySelector('#pathGroupRight')
                        const newPathRight = this.createPathElementRight(this[player])
                        rightSideSVG.appendChild(newPathRight) 
                    }
                } 
                else {
                    let parentSVG = document.querySelector('#puckGroup')
                    const paths = this.createPuckPaths('left')
                    for(let path of paths) {
                        parentSVG.appendChild(path)
                    }

                    let rightSVG = document.querySelector('#puckGroupRight')
                    const rightPaths = this.createPuckPaths('right')
                    for(let path of rightPaths) {
                        rightSVG.appendChild(path)
                    }
                }
            }
        }     
    }
}

</script>

<template>
<svg id='parentSvg' xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 700 750">
    <g :transform="leftSide ? 'none' : 'scale(-1, 1) translate(-700, 0)'">
        <g id="rink_bankground" data-name="rink bankground">
        <path class="cls-4" d="M700,0V750H0V211.07c0-26.75,3.98-52.32,11.24-75.87,.85-2.76,1.75-5.5,2.69-8.2,2.52-7.25,5.36-14.29,8.49-21.09,.67-1.45,1.35-2.89,2.04-4.31C54.08,40.68,107.67,0,168.86,0H700Z"/>
        <image class="cls-6" width="1300" height="1454" transform="translate(553.35 439.48) scale(.06)" :xlink:href="logoURL"/>
    </g>
    <g id="Lines">
        <path class="cls-3" d="M700,101.6v4.31H22.42c.67-1.45,1.35-2.89,2.04-4.31H700Z"/>
        <path class="cls-2" d="M647.3,109.45c0,25.47-25.55,46.12-57.07,46.12s-57.06-20.65-57.06-46.12c0-1.19,.06-2.37,.16-3.54h113.81c.11,1.18,.16,2.35,.16,3.54Z"/>
        <rect class="cls-1" y="696.8" width="700" height="15.27"/>
    </g>
    <g id="Faceoff_circle" data-name="Faceoff circle">
        <g>
        <circle class="cls-5" cx="273.22" cy="368.77" r="183.88"/>
        <circle class="cls-3" cx="273.22" cy="368.77" r="9.48"/>
        </g>
        <g>
        <rect class="cls-3" x="456.49" y="351" width="24.94" height="4.58"/>
        <rect class="cls-3" x="456.49" y="380.25" width="24.94" height="4.58"/>
        </g>
        <g>
        <rect class="cls-3" x="64.95" y="351.21" width="24.94" height="4.58"/>
        <rect class="cls-3" x="64.95" y="380.46" width="24.94" height="4.58"/>
        </g>
    </g>
    </g>
    <g v-show="showPath && leftSide" id="pathGroup"> <!-- We can add the elements to this group, then conditionally render with vif-->

    </g>
    <g v-show="showPath && !leftSide" id="pathGroupRight"> <!--These will be the paths rendered on the right hand side of the screen if the user chooses to see them-->
    
    </g>

    <g v-show="showPath && leftSide && showPuckPath" id="puckGroup"> <!-- We can add the elements to this group, then conditionally render with vif-->

    </g>
    <g v-show="showPath && !leftSide && showPuckPath" id="puckGroupRight"> <!--These will be the paths rendered on the right hand side of the screen if the user chooses to see them-->

    </g>
  <text v-if="LWO" ref="LWO" class="LWO" :x="leftSide ? LWO.x : LWO.rightSideX" :y="leftSide ? LWO.y : LWO.rightSideY" :fill="LWO.color" >{{ leftSide ? LWO.name : LWO.rightSideName }}</text>
  <text v-if="RWO" ref="RWO" class="RWO" :x="leftSide ? RWO.x : RWO.rightSideX" :y="leftSide ? RWO.y : RWO.rightSideY" :fill="RWO.color">{{ leftSide ? RWO.name : RWO.rightSideName }}</text>
  <text v-if="CO" ref="CO" class="CO" :x="leftSide ? CO.x : CO.rightSideX" :y="leftSide ? CO.y : CO.rightSideY" :fill="CO.color">C</text>
  <text v-if="LDO" ref="LDO" class="LDO" :x="leftSide ? LDO.x : LDO.rightSideX" :y="leftSide ? LDO.y : LDO.rightSideY" :fill="LDO.color">{{ leftSide ? LDO.name : LDO.rightSideName }}</text>
  <text v-if="RDO" ref="RDO" class="RDO" :x="leftSide ? RDO.x : RDO.rightSideX" :y="leftSide ? RDO.y : RDO.rightSideY" :fill="RDO.color">{{ leftSide ? RDO.name : RDO.rightSideName }}</text>
  
  <text v-if="LWD" ref="LWD" class="LWD" :x="leftSide ? LWD.x : LWD.rightSideX" :y="leftSide ? LWD.y : LWD.rightSideY" :fill="LWD.color" >{{ leftSide ? LWD.name : LWD.rightSideName }}</text>
  <text v-if="RWD" ref="RWD" class="RWD" :x="leftSide ? RWD.x : RWD.rightSideX" :y="leftSide ? RWD.y : RWD.rightSideY" :fill="RWD.color">{{ leftSide ? RWD.name : RWD.rightSideName }}</text>
  <text v-if="CD" ref="CD" class="CD" :x="leftSide ? CD.x : CD.rightSideX" :y="leftSide ? CD.y : CD.rightSideY" :fill="CD.color">C</text>
  <text v-if="LDD" ref="LDD" class="LDD" :x="leftSide ? LDD.x : LDD.rightSideX" :y="leftSide ? LDD.y : LDD.rightSideY" :fill="LDD.color">{{ leftSide ? LDD.name : LDD.rightSideName }}</text>
  <text v-if="RDD" ref="RDD" class="RDD" :x="leftSide ? RDD.x : RDD.rightSideX" :y="leftSide ? RDD.y : RDD.rightSideY" :fill="RDD.color">{{ leftSide ? RDD.name : RDD.rightSideName }}</text>
  <defs>
  <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="0" refY="3.5" orient="auto">
    <polygon points="0 0, 10 3.5, 0 7" fill="black" />
    </marker>
   </defs> <!--This adds arrow heads to the end of the svg paths we made-->
   <circle ref="puck" class="puck" :cx="leftSide ? 273.22 : 426.78" :cy="368.77" r="5" fill="black"/>

   <defs> <!-- Glow effevt-->
        <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
          <feGaussianBlur stdDeviation="5" result="coloredBlur" />
          <feMerge>
            <feMergeNode in="coloredBlur" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
    </defs>
</svg>
</template>

<style scoped>
.cls-4 {
    stroke: #b3b3b3;
}

.cls-4, .cls-5 {
    fill: #fff;
}

.cls-4, .cls-5, .cls-2 {
    stroke-miterlimit: 10;
    stroke-width: 7px;
}

.cls-5, .cls-2 {
    stroke: #ff0007;
   
}

.cls-2 {
    fill:#167ef5; 

}

.cls-3 {
    stroke: #ff0007;
    fill: #ff0007;
}

.cls-1 {
    fill: #167ef5;
}

.cls-6 {
    opacity: .03;
}

.LWD, .RWD, .CD, .LDD, .RDD {
    font-size: 260%;
    fill: red;
    text-anchor: middle;
    dominant-baseline:middle
}

.LWO, .RWO, .CO, .LDO, .RDO {
    font-size: 260%;
    fill:#167ef5;
    text-anchor: middle;
    dominant-baseline:middle
}

</style>