<template>
  <header class="flex justify-between items-center px-10 py-2 bg-gray-dark w-full max-w-7xl mx-auto">
    <img class="w-12" src="../assets/qu-logo.png" alt="QU Logo">
    <i class="material-icons-outlined text-white">menu</i>
  </header>
</template>

<script>
export default {
  name: 'NavBar',
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Outlined');
</style>