import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/index.css'; // Add this line
import 'flowbite';
import './utils/axiosConfig'
document.body.classList.add('dark');

const app = createApp(App)

app.use(router).mount('#app')
