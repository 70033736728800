<script>

import HockeyRink from '@/components/HockeyRink.vue'
import PlayButton from '@/components/PlayButton.vue'
import ResetButton from '@/components/ResetButton.vue'
import SwitchSidesButton from '@/components/SwitchSidesButton.vue'
import ViewPathButton from '@/components/ViewPathButton.vue'
import NewNavBar from './NewNavBar.vue'
import { format } from '@/utils/formatType.js'

export default {
    name: 'PlayComponent',
    components: {HockeyRink, PlayButton, ResetButton, SwitchSidesButton, ViewPathButton, NewNavBar},
    props: {
        LWO: {
            type: Object,
            required: false
        },
        LWD: {
            type: Object,
            required: false
        },
        CO: {
            type: Object,
            required: false
        },
        CD: {
            type: Object,
            required: false
        },
        RWO: {
            type: Object,
            required: false
        },
        RWD: {
            type: Object,
            required: false
        },
        LDO: {
            type: Object,
            required: false
        },
        LDD: {
            type: Object,
            required: false
        },
        RDO: {
            type: Object,
            required: false
        },
        RDD: {
            type: Object,
            required: false
        },
        puck: {
          type: Object,
          required: false
        },
        type: {
          type: String,
          required:true
        }
    },
    methods: { 
        reset() {
            this.$refs.hockeyRink.reset();
        },
        switchSides() {
            this.$refs.hockeyRink.switchSides();
        },
        handlePlayEvent() {
            this.$refs.hockeyRink.runPlay();
        },
        drawLines() {
          this.$refs.hockeyRink.drawLines();
        }
        
    },
    computed: {
        formattedType() {
          return format(this.type)
        }
    }
}
</script>

<template>
  <div class="max-w-6xl mx-auto px-4 h-screen flex flex-col">
    <NewNavBar :text="formattedType"/>
    <div class="flex flex-col justify-center items-center p-4 flex-1">
      <HockeyRink 
        ref="hockeyRink" 
        class="w-full max-h-full xs:max-w-112 sm:max-w-120 md:max-w-120 lg:max-w-120 xl:max-w-128 2xl:max-w-128 3xl:max-w-144"
        :LWO="LWO" 
        :LWD="LWD" 
        :CO="CO" 
        :CD="CD" 
        :RWO="RWO" 
        :RWD="RWD" 
        :LDO="LDO" 
        :LDD="LDD" 
        :RDO="RDO" 
        :RDD="RDD" 
        :puck="puck"
      />
      <div class="mt-4 mb-4 w-full flex justify-center">
        <div class="text-white grid grid-cols-2 gap-4">
          <PlayButton :isAnimated="$route.query.isAnimated === 'true'" @click="handlePlayEvent" class="px-6 py-3 text-lg"/>
          <ResetButton :isAnimated="$route.query.isAnimated === 'true'" @click="reset" class="reset-button px-6 py-3 text-lg"/>
          <SwitchSidesButton @click="switchSides" class="switch-button px-6 py-3 text-lg"/>
          <ViewPathButton :isAnimated="$route.query.isAnimated === 'true'" @click="drawLines" class="view-path-button px-6 py-3 text-lg"/>
        </div>
      </div>
    </div>
  </div>
</template>