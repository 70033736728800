import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginPage from '@/views/LoginPage.vue'
import GenericPlay from '@/views/GenericPlay.vue'
import axiosInstance from '@/utils/axiosConfig'

const routes = [
    {
      path: '/',
      redirect: '/login' //base page
    },
    {
      path: '/offense',
      name: 'Home',
      component: HomeView,
      meta: {
        requiresAuth:true,
      },
      props: {type : 'offense'}
    },
    {
      path: '/defensive-alignment',
      name: 'Defensive Alignment',
      component: HomeView,
      meta: {
        requiresAuth: true
      },
      props: {type : 'defensive-alignment'}
    },
    {
      path: '/offense/:name',
      name: 'Play',
      component: GenericPlay,
      props: true,
      meta: {
        requiresAuth:true,
      }
    },
    {
      path: '/defensive-alignment/:name',
      name: 'Defensive Alignment Play',
      component: GenericPlay,
      props: true,
      meta: {
        requiresAuth:true,
      } 
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginPage,
      meta: {
        requiresAuth:false
      }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  })

//adding navigation guard here
router.beforeEach(async (to, from, next) => {
  if (to.path === '/login') {
    next()
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      await axiosInstance.get(`${process.env.VUE_APP_API_ROUTE}/validate`)
      next()
    } catch (error) {
      next('/login');
    }
  } else {
    next()
  }
})
  
export default router