import axios from 'axios'
import router from '@/router';

const axiosInstance = axios.create({
  withCredentials: true, //adds cookie
});

axiosInstance.interceptors.request.use((config) => {
  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 401) {
    router.push('/login');
  }
  return Promise.reject(error)
});

export default axiosInstance;