<template>
  <nav class="bg-white border-gray-200 dark:bg-gray-900 dark:border-gray-700 relative">
    <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
      <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
        <img src="../assets/qu-logo.png" class="h-8" alt="QU Logo" />
      </a>
      <!-- <div>
        <h1 class="pl-5 text-center text-lg dark:text-white font-semi-bold opacity-90">{{ text }}</h1>
      </div> -->
      <button @click="toggleMenu" type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-dropdown" aria-expanded="false">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
      </button>
      <div class="hidden md:block md:w-auto" id="navbar-dropdown">
        <ul class="flex flex-col font-medium md:p-0 md:flex-row md:space-x-8 rtl:space-x-reverse">
          <li class="relative">
            <button @click="toggleDropdown" ref="dropdownButton" id="dropdownNavbarLink" class="flex items-center justify-between w-full py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 md:w-auto dark:text-white md:dark:hover:text-blue-500 dark:focus:text-white dark:border-gray-700 dark:hover:bg-gray-700 md:dark:hover:bg-transparent">Plays <svg class="w-2.5 h-2.5 ms-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/>
            </svg></button>
            <div v-if="isDropdownOpen" ref="dropdownMenu" id="dropdownNavbar" class="z-20 absolute left-0 font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 dark:divide-gray-600">
              <ul class="py-2 text-sm text-gray-700 dark:text-gray-400" aria-labelledby="dropdownLargeButton">
                <li>
                  <a href="#" @click="selectPage('offense')" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Offense</a>
                </li>
                <li>
                  <a href="#" @click="selectPage('defensive-alignment')" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Defensive Alignment</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Neutral Zone (Coming Soon)</a>
                </li>
                <li>
                  <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Special Teams (Coming Soon)</a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- Mobile menu dropdown -->
    <div v-if="isMenuOpen" class="md:hidden absolute left-0 right-0 z-10 mt-2 w-full bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
      <ul class="py-2 text-sm text-gray-700 dark:text-gray-400">
        <li>
          <a href="#" @click="selectPage('offense')" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Offense</a>
        </li>
        <li>
          <a href="#" @click="selectPage('defensive-alignment')" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Defensive Alignment</a>
        </li>
        <li>
          <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Neutral Zone (Coming Soon)</a>
        </li>
        <li>
          <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Special Teams (Coming Soon)</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import axiosInstance from '@/utils/axiosConfig'

export default {

  data() {
    return {
      isMenuOpen: false,
      isDropdownOpen: false
    };
  },
  props: {
    text: {
      type: String,
      required:true
    }
  },
  methods: {
    selectPage(path) {
      //hide the drop down menu when pressed, especially before the navigation occurs
      this.isDropdownOpen = false
      this.isMenuOpen = false
      this.goToPage(path)
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      if (this.isMenuOpen) {
        this.isDropdownOpen = false;
      }
    },
    async goToPage(path) {
      try {
        await axiosInstance.get(`${process.env.VUE_APP_API_ROUTE}/validate`)
        this.$router.push(`/${path}`)
      } catch (error) {
        console.error("Session validation failed:", error)
        this.$router.push('/login');
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.adjustDropdownPosition();
    },
    adjustDropdownPosition() {
      this.$nextTick(() => {
        const dropdownMenu = this.$refs.dropdownMenu;
        const dropdownButton = this.$refs.dropdownButton;
        if (dropdownMenu && dropdownButton) {
          const dropdownRect = dropdownMenu.getBoundingClientRect();
          const buttonRect = dropdownButton.getBoundingClientRect();
          const viewportWidth = window.innerWidth;

          if (buttonRect.left + dropdownRect.width > viewportWidth) {
            dropdownMenu.style.left = 'auto';
            dropdownMenu.style.right = '0';
          } else {
            dropdownMenu.style.left = '0';
            dropdownMenu.style.right = 'auto';
          }
        }
      });
    },
    handleClickOutside(event) {
      if (!this.$refs.dropdownMenu?.contains(event.target) && !this.$refs.dropdownButton?.contains(event.target)) {
        this.isDropdownOpen = false;
      }
      if (!event.target.closest('#navbar-dropdown') && !event.target.closest('button')) {
        this.isMenuOpen = false;
      }
    }
  },
  mounted() {
    window.addEventListener('resize', this.adjustDropdownPosition);
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.adjustDropdownPosition);
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>